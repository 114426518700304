<template>
  <div v-if="!loading && !(isWx || isQQ || isDy)" class="page" ref="root">
    <div class="header">
      <div class="top-bg">&nbsp;</div>
    </div>
    <div class="logo-box">
      <img class="logo" src="@/assets/images/logo1.png">
    </div>
    <div class="app-title">
      <h2>觅圈</h2>
    </div>
    <div v-if="isPC||isMac||isWin" class="both-platform">
      <div class="platform-box">
        <div class="app-box android-box" @click="installApk">
          <div class="app-icon"></div>
          <div class="app-text">Android</div>
          <div class="download-now">立即下载</div>
        </div>
        <div class="split">&nbsp;</div>
        <div class="app-box ios-box">
          <div class="app-icon"></div>
          <div class="app-text">iOS</div>
          <div class="download-now">扫描下方<br/>二维码</div>
        </div>
      </div>
      <div class="platform-version">
        <div class="version-box">
          <div class="version">版本:{{ apkVersion }}</div>
        </div>
        <div class="split">|</div>
        <div class="version-box">
          <div class="version">{{ version ? '版本:' + version : '暂无可用版本' }}</div>
        </div>
      </div>
      <div class="qr-box">
        <vue-qr :logoSrc="require('@/assets/images/logo.png')" :text="qrText" :size="400" :logoMargin="2"
                :logoCornerRadius="18" colorDark="#585f69" :logo-scale="0.22" :margin="30"></vue-qr>
        <div class="scan-info">手机扫码访问</div>

        <div class="install-notice">
          <div class="notice-p">安装遇到困难，请<a :href="docUrl">查看帮助</a></div>
        </div>
      </div>
    </div>
    <div v-else :class="'mobile-box' + (isIOS && isNoIosVersion ? ' no-version' : '')">
      <div class="version-info">
        <div v-if="!isAndroid" class="platform">适用于{{ isIOS ? 'iOS' : 'Android' }}设备</div>
        <div v-if="isAndroid" class="platform-notice">适用Android5.0及以上系统</div>
        <div class="version">版本:{{ version || apkVersion }} {{ build ? "(" + build + ")" : "" }}</div>
      </div>
      <div class="install-notice">
        <div v-if="isIOS" class="cant-download">手机网页版：<a :href="h5Url">{{ h5Url }}</a></div>
      </div>
      <template v-if="isIOS">
        <div v-if="apps.length > 0 || appleUrl" class="install-box">
          <a v-if="apps[0]" class="install-btn" href="javascript:"
             @click="install(apps[0])">{{ apps[1] ? '安装包1' : '安装' }}</a>
          <a v-if="apps[1]" class="install-btn" href="javascript:" @click="install(apps[1])">安装包2</a>
          <a v-if="appleUrl" class="install-btn" :href="appleUrl">立即下载</a>
        </div>
      </template>
      <div v-else class="install-box">
        <template v-if="!matchedBrand">
          <template v-for="(apk,idx) in matchBrand" :key="idx">
            <a v-if="!apk.hide" class="install-btn slim" href="javascript:" @click="installApkUrl(apk.url)">{{ apk.notice }}</a>
          </template>
        </template>
        <template v-if="matchedBrand">
            <a class="install-btn slim" href="javascript:" @click="installApkUrl(matchedBrand.url)">{{ matchedBrand.notice }}</a>
<!--            <a class="install-btn slim" href="javascript:" @click="installApk">其他请点这里下载</a>-->
        </template>
      </div>
      <div class="qr-box">
        <vue-qr :logoSrc="require('@/assets/images/logo.png')" :text="qrText" :size="400" :logoMargin="2"
                :logoCornerRadius="18" colorDark="#585f69" :logo-scale="0.22" :margin="30"></vue-qr>

        <div v-if="isIOS && apps.length > 0" class="ios-notice">
          <div>App如无法使用，请卸载后重新安装</div>
          <div>如遇无App版本，请先使用手机网页版</div>
        </div>

        <div v-if="isIOS && apps.length > 0" class="install-notice">
          <div class="notice-p">安装遇到困难，请<a :href="docUrl">查看帮助</a></div>
        </div>
      </div>


      <div v-if="isWeibo || isQQ || isWx || isDy" class="mask">
        <img v-if="isAndroid" src="@/assets/images/mask1.png">
        <img v-else src="@/assets/images/mask.png">
      </div>

      <div v-if="showInstall" class="mask">
        <div class="alert-box">
          <div class="now-download" id="now-download">请等待安装完成，然后开始设置</div>
          <div class="a1">若安装后无法正常使用，请删除旧版APP</div>
          <div class="a2">请务必按以下步骤进行设置，详细 <a :href="docUrl"
                                                           style="color: #00A4FF;">安装教程</a>。
          </div>
          <div class="course" style="max-width: 100%;">
            <img style="width: 100%;max-width: 100%;" :src="require('@/assets/picture/course_' +currentIdx+ '.gif')">
            <div class="course_p">{{ stepMessages[currentIdx] }}</div>
          </div>
          <div class="close-btn2" @click="closeInstall"></div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isWx || isQQ || isDy" class="wx-content">
    <div class="text"><h3>如需浏览，请复制网址后使用<br/>浏览器访问</h3><div>{{mainUrl}} <a href="javascript:" @click="copyUrl">复制</a></div></div>
  </div>
</template>

<script>
import {post, getCache} from "@/utils/net";
import {loading, hideLoading} from "@/utils/toast";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import useClipboard from 'vue-clipboard3'
import {Toast} from "vant";

const { toClipboard } = useClipboard();

export default {
  name: "AppInfo",
  components: {
    vueQr,
  },
  data: function () {
    return {
      version: "",
      build: "",
      ua: navigator.userAgent,
      appParam: {
        id: '',
        release: 1
      },
      apps: [],
      switchIdx: 0,
      currentIdx: 0,
      showInstall: false,
      stepMessages: ["第1步：取消弹窗提示", "第2步：打开桌面的“设置”功能", "第3步：打开“通用”设置", "第4步：打开“VPN与设备管理”", "第5步：点击“企业级APP”", "第6步：点击蓝色文字“信任”", "第7步：点击弹窗中的“信任”,完成设置"],
      isAndroid: false,
      isIOS: false,
      isQQ: false,
      isPC: false,
      isWin: false,
      isMac: false,
      isWx: false,
      isWeibo: false,
      isSafari: false,
      isDy: false,
      loading: true,
      apkVersion: "",
      apkUrl: "",
      apkUrl2: "",
      docUrl: "",
      isNoIosVersion: false,
      qrText: window.location.href,
      h5Url: "https://m.weme.fun",
      appleUrl: "",
      appleVersion: "",
      prefix: "itms-services://?action=download-manifest&url=",
      mainUrl: "",
      shareUrl: "",
      apk2Notice: "",
      apk2Match: "",
      matchBrand: [],
      matchedBrand: null,
      iOSMinVersion: []
    };
  },
  methods: {
    checkBrowser: function () {
      let isIosQQ = (/(iPhone|iPad|iPod|iOS)/i.test(this.ua) && /\sQQ/i.test(this.ua));
      let isAndroidQQ = (/(Android)/i.test(this.ua) && /MQQBrowser/i.test(this.ua) && /\sQQ/i.test(this.ua));
      let isAndroid = this.ua.indexOf('Android') > -1 || this.ua.indexOf('Adr') > -1; //android终端
      this.isQQ = isIosQQ || isAndroidQQ;
      this.isAndroid = isAndroid;
      this.isWeibo = /weibo/i.test(this.ua);
      this.isWx = /MicroMessenger/i.test(this.ua);
      this.isDy = /aweme|Bytedance/i.test(this.ua);

      if ((this.isWx || this.isQQ) && this.shareUrl && this.shareUrl !== window.location.href) {
        window.location.replace(this.shareUrl);
      } else if (!(this.isWx || this.isQQ) && this.mainUrl && this.shareUrl === window.location.href) {
        window.location.replace(this.mainUrl);
      }

      // check PC
      let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (this.ua.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      this.isPC = flag;

      let agent = this.ua.toLowerCase();
      let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      let MAC = !isIOS && /macintosh|mac os x/i.test(navigator.userAgent) && !window.MSStream;

      this.isWin = agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0 || agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0

      this.isIOS = isIOS;
      this.isMac = MAC;

      this.isSafari = this.ua.indexOf("Safari") > -1 && this.ua.indexOf("Chrome") === -1; //判断是否Safari浏览器

      if (this.matchBrand && this.matchBrand.length > 0) {
        for (let i = 0; i < this.matchBrand.length; i++) {
          let match = this.matchBrand[i].match;
          if (!match) {
            continue;
          }
          let regex = new RegExp(match);
          if (!isIOS && regex.test(navigator.userAgent.toLowerCase())) {
            this.matchedBrand = this.matchBrand[i];
            break;
          }
        }
      }
    },
    copyUrl: async function () {
      try {
        await toClipboard(this.mainUrl);
        Toast.success('复制成功');
      } catch (e) {
        console.error(e);
      }
    },
    loopImage: function (currentIdx) {
      currentIdx = currentIdx + 1;
      if (currentIdx > 6) {
        currentIdx = 0;
      }
      let vm = this;
      this.currentIdx = currentIdx;
      clearTimeout(this.switchIdx);
      this.switchIdx = setTimeout(function () {
        vm.loopImage(vm.currentIdx);
      }, 4000);
    },
    increaseDownload: async function (uuid) {
      let l = loading();
      try {
        let res = await post("https://test.wemiquan.com/Home/IncreaseDownload", {uuid, release: this.appParam.release});
        if (res.code === 404) {
          return false;
        }
      } catch (e) {
        console.log(e);
      }
      hideLoading(l);
      return true;
    },
    checkScroll: function (e) {
      let top = this.$refs["root"].scrollTop;
      if (top === 0) {
        return;
      }
      e.preventDefault();
    },
    install: async function (item) {
      if (this.iOSMinVersion && this.iOSMinVersion.length > 0) {
        const regex = /iPhone OS (\d+)_(\d+)(_(\d+))? /;
        const ua = navigator.userAgent;
        const matched = ua.match(regex);
        if (matched) {
          if (matched[1] < this.iOSMinVersion[0]
              || matched[1] === this.iOSMinVersion[0] && matched[2] < this.iOSMinVersion[1]
              || matched[1] === this.iOSMinVersion[0] && matched[2] === this.iOSMinVersion[1] && (typeof (matched[4]) == "undefined" || matched[4] < this.iOSMinVersion[2])) {
            Toast.fail("系统版本低于" + this.iOSMinVersion.join(".") + "，不能安装");
            return;
          }
        }
      }
      this.showInstall = true;
      this.loopImage(-1);
      let res = await this.increaseDownload(item.uuid);
      if (res) {
        window.location.href = this.prefix + item.plistUrl;
      } else {
        window.location.reload();
      }
    },
    installApkUrl: function (url) {
      window.location.href = url;
    },
    installApk: function () {
      window.location.href = this.apkUrl;
    },
    installApk2: function () {
      window.location.href = this.apkUrl2;
    },
    closeInstall: function () {
      clearTimeout(this.switchIdx);
      this.currentIdx = 0;
      this.showInstall = false;
    },
    loadApp: async function () {
      if (this.isIOS || this.isPC || this.isMac || this.isWin) {
        let l = loading();
        try {
          let res = await post("https://test.wemiquan.com/Home/Last", this.appParam);
          if (res.code === 200) {
            let data = res.data;
            if (data && data.length > 0) {
              this.version = data[0].appVersion;
              this.build = data[0].build;
              this.apps = data;
            } else if (this.appleUrl) {
              this.version = this.appleVersion;
            } else {
              if (this.isIOS && this.isSafari) {
                window.location.href = this.h5Url;
              }
              this.isNoIosVersion = true;
              this.version = "暂无版本";
            }
          }
        } catch (e) {
          console.log(e);
        }
        hideLoading(l);
      }
    }
  },
  async mounted() {
    try {
      let file = window.configFile || "/config.json";
      let config = await getCache(file);
      if (config) {
        this.appParam.id = config.id;
        this.appParam.release = config.release;
        this.apkVersion = config.apkVersion;
        this.apkUrl = config.apkUrl;
        this.apkUrl2 = config.apkUrl2 || "";
        this.docUrl = config.docUrl;
        this.h5Url = config.h5Url || this.h5Url;
        this.appleUrl = config.appleUrl || this.appleUrl;
        this.appleVersion = config.appleVersion || this.appleVersion;
        this.mainUrl = config.mainUrl || this.mainUrl;
        this.shareUrl = config.shareUrl || this.shareUrl;
        this.apk2Notice = config.apk2Notice || this.apk2Notice;
        this.apk2Match = config.apk2Match || this.apk2Match;
        this.matchBrand = config.matchBrand || this.matchBrand;
        this.iOSMinVersion = config.iOSMinVersion || this.iOSMinVersion;
      }
    } catch (e) {
      console.log(e);
    }

    this.checkBrowser();
    await this.loadApp();
    this.loading = false;
  }
}
</script>

<style scoped>
.logo-box {
  padding: 10px;
  margin-top: 40px;
}

.page {
  text-align: center;
  max-width: 640px;
  margin: auto;
  overflow: auto;
  height: 100%;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.page .header {
  height: 100px;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  top: 0;
  z-index: -1;
}

.page .header .top-bg {
  max-width: 1000px;
  height: 100px;
  background: var(--blend-color) url("~@/assets/images/girls.png") no-repeat center bottom;
  background-size: 100% auto;
  margin: auto;
  background-blend-mode: var(--blend-mode);
}

@media screen and (max-width: 640px) {
  .page .header {
    height: 120px;
  }

  .page .header .top-bg {
    height: 120px;
  }
}

.page .logo {
  width: 100px;
  height: 100px;
  border-radius: 24px;
}

.app-title {
  margin-top: 10px;
  margin-bottom: 30px;
}

.app-title h2 {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: -5px;
}

.version-info {
  color: var(--van-text-color-2);
  font-size: 14px;
  line-height: 1.6;
}

.version-info > div {
  display: inline-block;
}

.platform {
  border-right: 1px solid #DEE2EC;
  padding-right: 10px;
  margin-right: 5px;
}
.platform-notice {
  border-right: 1px solid transparent;
  padding-right: 10px;
  margin-right: 5px;
  color:#dd1100;
}

.version-info .version {
  margin-left: 5px;
}

.install-box {
  margin-top: 30px;
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  align-items: center;
}

.install-btn {
  background: linear-gradient(to right, var(--btn-primary), var(--btn-primary));
  line-height: initial;
  border: 1px solid transparent;
  display: inline-block;
  color: var(--van-btn-color);
  text-decoration: none;
  padding: 8px 60px;
  border-radius: 30px;
  font-size: 18px;
}
.install-btn.slim{
  padding: 8px 15px;
}

.install-btn + .install-btn {
  margin-top: 10px;
}

.install-btn:hover {
  background: #28caad !important;
}

.install-notice {
  margin: 15px 0;
  font-size: 14px;
  line-height: 24px;
  color: var(--van-text-color);
}

.install-notice a {
  color: #00a4ff;
}

.cant-download {
  font-size: 20px;
  font-weight: 600;
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
}

.mask img {
  width: 100%;
}

.now-download {
  width: 100%;
  color: var(--van-text-color);
  margin-bottom: 14px;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
}

.a1 {
  font-size: 15px;
  color: var(--van-text-color-2);
  text-align: center;
  margin-bottom: 17px;
}

.a2 {
  font-size: 15px;
  text-align: center;
  margin-bottom: 17px;
  font-weight: 500;
  color: #FF3333;
}

.course_p {
  max-width: 340px;
  height: 44px;
  line-height: 44px;
  padding: 0 11px;
  margin: 0 auto;
  font-size: 15px;
  color: var(--setup-color);
  text-align: center;
  background: var(--setup-background);
}

.alert-box {
  max-width: 500px;
  position: absolute;
  width: 86%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: var(--van-background);
  height: auto;
  padding: 3%;
  border-radius: 11px;
}

.close-btn2 {
  width: 28px;
  height: 28px;
  position: absolute;
  z-index: 3;
  background: url(~@/assets/images/close.png) no-repeat center;
  background-size: 100% auto;
  top: auto;
  bottom: -35px;
  left: 50%;
  margin-left: -14px;
}

.both-platform .qr-box {
  margin-top: 30px;
}

.qr-box img {
  width: 150px;
  padding: 3px;
  margin-top: 10px;
}

.both-platform {
  text-align: center;
}

.platform-box, .platform-version {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  width: 320px;
  margin: auto;
}

.platform-version {
  margin-top: 10px;
}

.both-platform .app-box {
  width: 80px;
  height: 80px;
  border-radius: 60px;
  background: #6cd0bc;
  padding: 15px;
  box-sizing: border-box;
  position: relative;
  color: white;
}

.split {
  color: #eeeeee;
}

.platform-version .version-box {
  width: 100px;
  text-align: center;
  font-size: 13px;
}

.android-box .app-icon {
  background: url("~@/assets/images/index_icon_android.png") center center no-repeat;
}

.ios-box .app-icon {
  background: url("~@/assets/images/index_icon_ios.png") center center no-repeat;
}

.both-platform .app-icon {
  height: 30px;
  width: 30px;
  margin: auto;
  background-size: 30px auto;
  transition: all .6s;
}

.both-platform .app-text {
  margin-top: 5px;
  line-height: 24px;
  transition: all .6s;
  font-size: 11px;
}

.both-platform .download-now {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  transition: all .6s;
  opacity: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.app-box:hover .download-now {
  opacity: 1;
}

.app-box:hover .app-icon {
  opacity: 0;
}

.app-box:hover .app-text {
  opacity: 0;
}

.both-platform .install-notice {
  color: var(--van-text-color);
}

.ios-notice {
  font-size: 16px;
  color: var(--van-text-color);
}

.no-version .qr-box img {
  display: none !important;
}

.no-version .install-notice {
  margin: 30px auto;
}

.no-version .cant-download {
  font-size: 20px;
  line-height: 40px;
}
.wx-content {
  height:100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: center;
}
</style>